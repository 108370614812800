import Breakpoints from '@utils/breakpoints'
import { ButtonStyle, Colors } from '@utils/css-variables'
import { makeClasses } from '@utils/styles'
import { thirdStepSubSteps } from './create_tn'
import { INVOICE_STATUS } from '@services/constants'
import { IInvoice } from '@services/types/invoice'
import moment from 'moment'

type ClassType = {
	root: string
	root2: string
	content: string
	contentPdf: string
	informationBank: string
	section: string
	confirmationContainer: string
	h3: string
	inputGroup: string
	inputLabel: string
	inputText: string
	inputTextarea: string
	inputDescLabel: string
	btn: string
	submitRoot: string
	submitBtn: string
	container: string
	sectionTitle: string
	sectionTitleRoot: string
	sectionTitleRootLowPadding: string
	progressBar: string
	checkBox: string
	informationContainer: string
	loader: string
	confirmationAlert: string
	btnDisabled: string
	pdfFormcontainer: string
	sectionBlueTitle: string
	textSection: string
	section2: string
	section3: string
	divider: string
	text: string
	h4: string
	h5: string
	button: string
	buttonLink: string
	next: string
	lineDivider: string
	applicantInformation: string
	infoTitle: string
	buttonOutline: string
	buttonContainer: string
	breadcrumb: string
	noMarginBottom: string
	shevronIcon: string
	secondaryColor: string
	subTitle: string
	summarySection: string
	noPadding: string
	noBackground: string
	checkbox2: string
	checkbox2Label: string
	checkbox2Container: string
	collapseContent: string
	collapse: string
	fileSection: string
	headerWrapper: string
	containerFlex: string
	containerItem1: string
	containerItem2: string
	accordionTitle: string
	contentWrapper: string
	loadingMessage: string
	submitContainer: string
	title: string
	centerItem: string
	withCompactFooter: string
	toPrintTitle: string
	policyLabel: string
	policyContainer: string
	description: string
	bloc: string
	paymentDetails: string
	summaryContainer: string
	bottomSpace: string
	loadingOverlay: string
	modalContent: string
	containerUrbanism: string
	sidebarUrbanism: string
	contentUrbanism: string
}

export const classes: ClassType = makeClasses({
	root: {
		position: 'relative' as 'relative',
		'&.portal-safari,&.portal-ios': {
			select: {
				WebkitAppearance: 'none',
				WebkitBorderRadius: 0,
				backgroundImage:
					'url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSdibGFjaycgaGVpZ2h0PScyNCcgdmlld0JveD0nMCAwIDI0IDI0JyB3aWR0aD0nMjQnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTcgMTBsNSA1IDUtNXonLz48cGF0aCBkPSdNMCAwaDI0djI0SDB6JyBmaWxsPSdub25lJy8+PC9zdmc+)',
				backgroundRepeat: 'no-repeat',
				backgroundPositionX: '100%',
				backgroundPositionY: '50%'
			}
		},
		section: {
			[Breakpoints.minWidth('md')]: {
				padding: '40px'
			},
			background: Colors.white,
			padding: '20px',
			marginTop: '20px',
			p: {
				margin: 0
			},
			[Breakpoints.maxWidth('md')]: {
				padding: '30px 20px'
			}
		}
	},
	root2: {
		position: 'relative' as 'relative',
		'&.portal-safari,&.portal-ios': {
			select: {
				WebkitAppearance: 'none',
				WebkitBorderRadius: 0,
				backgroundImage:
					'url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSdibGFjaycgaGVpZ2h0PScyNCcgdmlld0JveD0nMCAwIDI0IDI0JyB3aWR0aD0nMjQnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTcgMTBsNSA1IDUtNXonLz48cGF0aCBkPSdNMCAwaDI0djI0SDB6JyBmaWxsPSdub25lJy8+PC9zdmc+)',
				backgroundRepeat: 'no-repeat',
				backgroundPositionX: '100%',
				backgroundPositionY: '50%'
			}
		}
	},
	withCompactFooter: {
		minHeight: '100vh',
		paddingBottom: '50px'
	},
	content: {
		[Breakpoints.minWidth('xl')]: {
			maxWidth: 'calc(100% - 350px)',
			width: '100%'
		}
	},
	contentPdf: {
		[Breakpoints.minWidth('xl')]: {
			maxWidth: '100%',
			width: '100%'
		}
	},
	informationBank: {
		[Breakpoints.minWidth('xl')]: {
			position: 'fixed' as 'fixed',
			overflow: 'auto',
			minHeight: '100%',
			right: 0,
			top: 0,
			bottom: 0
		}
	},
	section: {
		background: Colors.white,
		padding: '40px',
		marginTop: '20px',
		p: {
			margin: 0
		},
		[Breakpoints.maxWidth('md')]: {
			padding: '30px 20px'
		}
	},
	section3: {
		background: Colors.white,
		marginTop: '20px',
		p: {
			margin: 0
		},
		'& > div': {
			padding: '0 40px 40px',
			marginTop: '20px',
			[Breakpoints.maxWidth('md')]: {
				padding: '30px 20px'
			}
		}
	},
	confirmationContainer: {
		'& .css-1g9vew2': {
			marginBottom: '-15px !important'
		},
		[Breakpoints.minWidth('xl')]: {
			padding: '40px 80px !important'
		}
	},
	h3: {
		color: Colors.white,
		marginTop: 0,
		marginBottom: '25px',
		backgroundColor: Colors.secondary,
		padding: '15px 40px',
		fontWeight: 500,
		[Breakpoints.maxWidth('md')]: {
			padding: '15px 20px'
		}
	},
	inputGroup: {
		marginBottom: '30px'
	},
	inputLabel: {
		color: Colors.lightBlack
	},
	inputText: {
		color: Colors.lightBlack,
		width: '100%'
	},
	inputTextarea: {
		height: '136px',
		color: Colors.lightBlack,
		fontSize: '16px',
		fontWeight: 400,
		width: '100%'
	},
	inputDescLabel: {
		display: 'block',
		margin: '5px 0 10px',
		padding: '0 40px',
		fontSize: '0.85em',
		lineHeight: '1.25em',
		color: Colors.lightGrey2,
		'& p': {
			marginBottom: '10px'
		},
		[Breakpoints.maxWidth('md')]: {
			padding: '0 20px'
		}
	},
	btn: {
		...ButtonStyle,
		background: Colors.secondary,
		color: Colors.white
	},
	submitRoot: {
		display: 'flex',
		alignItems: 'center',
		p: {
			margin: 0
		}
	},
	submitBtn: {
		justifyContent: 'center',
		minWidth: '117px',
		height: '46px',
		padding: '15px 30px',
		fontSize: '16px',
		border: '0',
		'&:hover': {
			cursor: 'pointer'
		}
	},
	container: {
		maxWidth: '1060px',
		padding: '40px 80px',
		margin: 'auto',
		[Breakpoints.maxWidth('xl')]: {
			padding: '40px'
		},
		[Breakpoints.maxWidth('md')]: {
			padding: '0'
		}
	},
	informationContainer: {
		maxWidth: '1060px',
		padding: '30px 80px 20px',
		margin: 'auto',
		[Breakpoints.maxWidth('xl')]: {
			padding: '40px'
		},
		[Breakpoints.maxWidth('md')]: {
			padding: '0'
		}
	},
	sectionTitle: {
		background: Colors.primary
	},
	sectionTitleRootLowPadding: {
		padding: '30px 0px !important',
		[Breakpoints.maxWidth('xl')]: {
			padding: '40px !important'
		},
		[Breakpoints.maxWidth('md')]: {
			padding: '20px !important'
		}
	},
	sectionTitleRoot: {
		maxWidth: '1060px',
		padding: '30px 80px',
		margin: 'auto',
		[Breakpoints.maxWidth('xl')]: {
			padding: '40px'
		},
		[Breakpoints.maxWidth('md')]: {
			padding: '20px'
		}
	},
	progressBar: {
		[Breakpoints.maxWidth('xl')]: {
			padding: '0 40px'
		},
		[Breakpoints.maxWidth('md')]: {
			padding: '0 20px'
		}
	},
	checkBox: {
		fontWeight: 400,
		alignItems: 'flex-start'
	},
	loader: {
		position: 'absolute' as 'absolute',
		border: `4px solid ${Colors.lightGrey}`,
		borderTop: `4px solid ${Colors.blue}`,
		borderRadius: '50%',
		width: '26px',
		height: '26px',
		animation: 'spin 2s linear infinite'
	},
	confirmationAlert: {
		paddingBottom: 0
	},
	btnDisabled: {
		opacity: 0.8,
		pointerEvents: 'none',
		cursor: 'not-allowed',
		'&:hover': {
			background: Colors.secondary,
			cursor: 'not-allowed'
		}
	},
	pdfFormcontainer: {
		color: Colors.lightBlack,
		marginTop: '20px'
	},
	sectionBlueTitle: {
		background: Colors.secondary,
		padding: '15px 20px',
		lineHeight: '10px',
		[Breakpoints.maxWidth('md')]: {
			padding: '8px 20px'
		},
		color: Colors.white,
		'& h3': {
			fontWeight: 500,
			fontSize: '22px',
			margin: 0,
			[Breakpoints.maxWidth('md')]: {
				fontSize: '16px'
			}
		}
	},
	textSection: {
		padding: '20px',
		[Breakpoints.minWidth('md')]: {
			padding: '40px'
		},
		background: Colors.white,
		'& h4:first-of-type': {
			marginTop: '0px'
		},
		'& ul': {
			paddingLeft: '0',
			listStyle: 'none',
			margin: '5px 0 30px',
			'& li': {
				paddingLeft: '15px',
				position: 'relative',
				marginBottom: '10px',
				fontFamily: '"Roboto", "Helvetica Neue", Arial',
				fontSize: '16px',
				fontWeight: '400',
				color: '#0a0a0a',
				lineHeight: '20px',
				verticalAlign: 'baseline',
				WebkitFontSmoothing: 'antialiased !important',
				'::before': {
					content: '""',
					position: 'absolute',
					width: '4px',
					height: '4px',
					top: '7px',
					left: '2px',
					borderRadius: '50%',
					backgroundColor: `${Colors.secondary}`
				},
				'& ul': {
					margin: '10px 0 20px 15px'
				}
			}
		}
	},
	section2: {
		background: Colors.white
	},
	text: {
		margin: '0 0 15px',
		padding: '0',
		fontWeight: '400',
		fontSize: '16px',
		lineHeight: '25px'
	},
	h4: {
		margin: '30px 0 15px',
		padding: '0',
		fontFamily: '"Roboto", Arial, Helvetica, sans-serif',
		fontWeight: '700',
		fontSize: '20px',
		lineHeight: '22px'
	},
	h5: {
		fontSize: '20px',
		fontWeight: '600',
		margin: '20px 0 20px'
	},
	button: {
		cursor: 'pointer',
		padding: '15px 30px',
		fontSize: '16px',
		marginLeft: '40px',
		height: '48px',
		[Breakpoints.maxWidth('sm')]: {
			padding: '15px 24px',
			fontSize: '15px',
			':first-child': {
				marginLeft: '20px'
			}
		}
	},
	buttonOutline: {
		backgroundColor: 'transparent',
		border: `1px solid ${Colors.secondary}`,
		color: `${Colors.secondary}`
	},
	next: {
		'& a': {
			color: Colors.white + ' !important'
		}
	},
	lineDivider: {
		display: 'flex',
		margin: '25px 0',
		justifyContent: 'space-between',
		borderTop: `1px solid ${Colors.lightGrey6}`,
		borderBottom: `1px solid ${Colors.lightGrey6}`,
		width: '100%',
		padding: '20px 0',
		[Breakpoints.maxWidth('md')]: {
			flexDirection: 'column'
		}
	},
	applicantInformation: {
		lineHeight: '25px'
	},
	infoTitle: {
		margin: '5px 0px',
		':first-child': {
			marginTop: '0px'
		},
		'& span': {
			display: 'block',
			margin: '0px 0px 15px'
		}
	},
	buttonContainer: {
		display: 'flex',
		margin: '40px 0px 40px 0px',
		alignItems: 'center'
	},
	breadcrumb: {
		margin: '25px 0 0px',
		fontSize: '12px',
		lineHeight: '16px'
	},
	noMarginBottom: {
		marginBottom: '-20px !important'
	},
	shevronIcon: {
		width: '10px'
	},
	secondaryColor: {
		color: Colors.lightBlue
	},
	subTitle: {
		fontSize: '22px',
		margin: '0px',
		marginBottom: '30px',
		color: Colors.darkBlue1,
		lineHeight: '22px',
		padding: '0',
		fontWeight: '700'
	},
	summarySection: {
		paddingBottom: '40px',
		'& section': {
			paddingLeft: '0px',
			paddingRight: '0px',
			':first-of-type': {
				paddingTop: '0px'
			},
			'& h3': {
				fontSize: '22px',
				margin: '0 0 30px 0'
			}
		}
	},
	buttonLink: {
		color: Colors.secondary,
		'&:hover': {
			cursor: 'pointer',
			textDecoration: 'underline'
		}
	},
	noPadding: {
		padding: '0px !important'
	},
	noBackground: {
		background: 'none !important'
	},
	checkbox2: {
		width: 'fit-content',
		position: 'absolute',
		verticalAlign: 'baseline',
		clip: 'rect(1px, 1px, 1px, 1px)'
	},
	checkbox2Label: {
		fontSize: '16px',
		padding: '0',
		color: Colors.lightBlack,
		position: 'relative',
		paddingLeft: '27px',
		fontWeight: 'normal',
		cursor: 'pointer',
		lineHeight: '17px',
		':before': {
			content: "''",
			position: 'absolute',
			top: '0',
			left: '0',
			width: '16px',
			height: '16px',
			border: `1px solid ${Colors.lightGrey3}`,
			background: Colors.white,
			outline: 'none !important'
		},
		':after': {
			content: "''",
			position: 'absolute',
			top: '4px',
			left: '4px',
			width: '10px',
			height: '10px',
			background: Colors.secondary,
			outline: 'none',
			opacity: '0',
			transition: 'all .15s ease-out'
		}
	},
	checkbox2Container: {
		marginTop: '30px',
		'& input[type=checkbox]:checked ~ label:after': {
			opacity: '.99'
		},
		[Breakpoints.maxWidth('xl')]: {
			marginLeft: '40px',
			marginRight: '40px'
		},
		[Breakpoints.maxWidth('sm')]: {
			marginLeft: '20px',
			marginRight: '20px'
		}
	},
	collapseContent: {
		fontSize: '16px !important',
		marginTop: '10px'
	},
	collapse: {
		'& > div[id^="collapse-"i]': {
			borderBottom: `1px solid ${Colors.grey}`,
			':first-of-type': {
				borderTop: `1px solid ${Colors.grey}`
			}
		}
	},
	fileSection: {
		marginTop: '20px',
		paddingBottom: '20px',
		backgroundColor: Colors.white,
		h3: {
			color: Colors.white,
			marginTop: 0,
			marginBottom: '25px',
			backgroundColor: Colors.secondary,
			padding: '15px 40px',
			fontWeight: 500,
			width: '100%'
		},
		a: {
			margin: '0 40px'
		},
		'& .css-12u1mqq .css-1wj64af': {
			margin: '0px 40px'
		},
		'& .css-gb3tzh': {
			margin: '0px 40px'
		},
		[Breakpoints.maxWidth('md')]: {
			h3: {
				padding: '15px 20px'
			},
			a: {
				margin: '0 20px'
			},
			'& .css-12u1mqq .css-1wj64af': {
				margin: '10px 20px'
			},
			'& .css-gb3tzh': {
				margin: '0px 20px'
			}
		},
		'& section': {
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
			alignItems: 'center',
			'& span': {
				alignSelf: 'start'
			}
		},
		'& .css-1a6inn4': {
			width: '91%',
			[Breakpoints.maxWidth('lg')]: {
				width: '88%'
			},
			[Breakpoints.maxWidth('md')]: {
				width: '90%'
			},
			[Breakpoints.maxWidth('sm')]: {
				width: '88%'
			}
		},
		'& .css-12u1mqq': {
			width: '100%'
		}
	},
	headerWrapper: {
		marginBottom: '30px'
	},
	containerFlex: {
		display: 'flex',
		gap: '20px',
		[Breakpoints.maxWidth('md')]: {
			flexDirection: 'column'
		}
	},
	containerItem1: {
		flex: 3
	},
	containerItem2: {
		flex: 2
	},
	accordionTitle: {
		'& .css-61pe59': {
			fontSize: '20px !important'
		},
		'& .css-1rsfb2f': {
			padding: '10px 20px !important',
			fontSize: '14px !important',
			height: 'auto !important'
		}
	},
	contentWrapper: {
		marginTop: '20px !important'
	},
	loadingMessage: {
		marginLeft: '10px',
		[Breakpoints.maxWidth('laptop')]: {
			marginBottom: '20px'
		}
	},
	submitContainer: {
		display: 'flex',
		alignItems: 'center',
		margin: '35px 20px 20px',
		[Breakpoints.minWidth('md')]: {
			margin: '50px 40px'
		},
		[Breakpoints.maxWidth('laptop')]: {
			marginTop: '20px',
			flexDirection: 'column-reverse',
			alignItems: 'start'
		}
	},
	title: {
		color: Colors.dark,
		fontSize: '14px',
		fontWeight: 700,
		lineHeight: '20px'
	},
	centerItem: {
		justifyContent: 'center'
	},
	toPrintTitle: {
		'& > div[id^="collapse-"i]': {
			borderTop: 'none !important',
			borderBottom: 'none !important'
		},
		'& > div[id^="collapse-"i] .button': {
			fontSize: '30px !important',
			fontWeight: 700
		},
		'& > div > div[id^="collapse-button-"i]': {
			display: 'none !important',
			'&::before': {
				content: '""',
				width: '0px !important',
				height: '0px !important'
			}
		},
		'& div>.css-15sa9uj': {
			display: 'none !important'
		}
	},
	policyLabel: {
		fontSize: '14px',
		fontWeight: 400
	},
	policyContainer: {
		marginTop: '-5px',
		marginLeft: '65px'
	},
	description: {
		clear: 'both',
		display: 'block',
		margin: '5px 0px 3px',
		padding: '0',
		fontSize: '0.85em',
		lineHeight: '1.25em',
		color: Colors.lightGrey2,
		marginTop: '10px !important',
		fontWeight: '400',
		'& a': {
			color: Colors.secondary,
			textDecoration: 'none',
			'&:hover': {
				textDecoration: 'underline'
			}
		}
	},
	bloc: {
		marginBottom: '30px',
		[Breakpoints.maxWidth('md')]: {
			marginTop: '20px'
		}
	},
	paymentDetails: {
		background: Colors.white,
		padding: '40px'
	},
	summaryContainer: {
		display: 'grid',
		gap: '2%',
		gridTemplateColumns: '59%39%',
		[Breakpoints.maxWidth('md')]: {
			gridTemplateColumns: '1fr'
		}
	},
	bottomSpace: {
		marginBottom: '40px'
	},
	loadingOverlay: {
		fontSize: '18px',
		background: 'rgba(255,255,255,0.8)',
		position: 'absolute',
		top: 0,
		left: 0,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'Center',
		width: '100%',
		height: '100vh',
		zIndex: '9999',
		lineHeight: '24px',
		'& :first-child': {
			position: 'relative',
			height: '40px',
			width: '40px',
			marginBottom: '15px'
		},
		'& div': {
			marginBottom: '10px'
		}
	},
	modalContent: {
		color: Colors.black2,
		fontSize: '16px',
		lineHeight: '26px',
		textAlign: 'center'
	},

	containerUrbanism: {
		display: 'flex',
		flexDirection: 'row',
		gap: '20px',
		[Breakpoints.maxWidth('md')]: {
			flexDirection: 'column',
			gap: '0px'
		}
	},
	sidebarUrbanism: {
		flex: '0 0 20%',
		maxWidth: '250px',
		[Breakpoints.maxWidth('md')]: {
			flex: '1 1 auto',
			maxWidth: '100%',
		}
	},
	contentUrbanism: {
		flex: '1 1 auto'
	}
})

export type Props = {
	location: Location
	pageContext: {
		id: string
	}
}

export type EligibilityContent = {
	title: string
	summary: string
	doc?: Document
}

export type PostData = {
	customer_id: string
	overview: string
	incident_type_id: string
	description?: string
	location_lat?: number
	location_lng?: number
	files?: File[]
	[key: string]: string | number | File[] | undefined
	incident_form_id: string
	form_type_id: string
}

export enum FORMS_ID {
	CLOTH_DIAPER_FROM = 'e20a25d7-6dab-ec11-983e-0022486ddaa2',
	RAIN_WATER_BARREL_FROM = 'fd864a72-e118-ed11-b83f-000d3a09f295',
	REPLACEMENT_OIL_HEATING_SYSTEM = '0b78f4f3-e018-ed11-b83f-000d3a09f295',
	HERITAGE_BUILDINGS_REVITALIZATION_PROGRAM = '5b2bf6f6-2577-ed11-81ac-000d3a09f295',
	CRACKED_HOUSES_FORM = 'c5d44606-af7f-ed11-81ac-000d3a09ccf4',
	TREE_PLANTING_FORM = 'acd5c696-dca0-ed11-aad0-000d3a09f295',
	TEMPORARY_PESTICIDE_APPLICATION_PERMIT_FOR_INDIVIDUALS_FORM = '167e327b-b2b7-ed11-b596-000d3af4f456',
	CLAIM_NOTICE_FORM = '827016d1-39b9-ed11-b596-000d3af4f456',
	PUBLIC_AUCTIONS = 'df162a89-13c8-ed11-b596-000d3af4fd6d',
	RECRUITMENT_AND_CITIZEN_INVOLVEMENT_FORM = 'RECRUITMENT_AND_CITIZEN_INVOLVEMENT_FORM',
	BUILDING_TRANSACTION_REQUEST_FORM = '150b31de-68d2-ed11-a7c7-000d3af4f456',
	OIL_HEATING_DECLARATION_FORM = '4dca305d-b9df-ed11-8847-000d3af4fd6d',
	HISTORICAL_ARCHIVE_FORM = '9baf056a-b7ea-ed11-a7c7-000d3af4f456',
	ANNUAL_REGISTRATION_CERTIFICATE_FOR_CONTRACTORS = 'f543bfe5-3eef-ed11-8848-000d3af4fd6d',
	SELF_RELIEF_WATER_COUNTER_FORM = '46271b3a-ce0e-ee11-8f6e-000d3af4fd6d',
	HYDRAULIC_AND_PRESSURE_TESTS_FORM = '808c5d39-6d10-ee11-8f6e-000d3af4fd6d',
	FIRE_HYDRANTS_TEMP_AQUEDUCT_NETWORK = 'c3895ddf-4d31-ee11-bdf4-000d3af4fd6d',
	FIRE_HYDRANTS_TANK_FILLING = '7eb3a7bc-6331-ee11-bdf4-000d3af4f895',
	MUNICIPAL_EVALUATION_FORM = '885ae74f-ecc9-ee11-9079-000d3af4fd6d',
	ANNUAL_PERMIT_AND_TAG_FOR_ANIMALS = 'd07d9bc3-2369-ee11-9ae7-000d3af4f456',
	FIREPLACE_DECLARATION = 'FIREPLACE_DECLARATION',
	PARKING_PERMIT = 'cd837486-8117-ef11-840b-7c1e52066ee5',
	PERMIT_OCCUPATION_OF_PUBLIC_HIGHWAY = '55505861-3edb-ee11-904c-000d3af400e5',
	SUSTAINABLE_HYGIENE_PRODUCTS = 'da3756fd-32f1-ee11-a1fd-0022486e1836',
	CAR_SHARING_GRANT = '0684da78-5bf3-ee11-a1fd-002248d5a7ad',
	BIKE_SHARING_GRANT = 'a8e96716-3af8-ee11-a1fe-000d3af400e5',
	FIREPLACE_GRANT = '25ef3e45-8917-ef11-840b-7c1e52066ee5',
	SWIMMING_POOL_SPA_PERMIT = '0b359890-7482-ef11-ac21-000d3af4b259',
	BUILDING_CONSTRUCTION_OR_ADDITION_PERMIT = 'BUILDING_CONSTRUCTION_OR_ADDITION_PERMIT'
}

type PageUrlDic = {
	[key: string]: {
		url: string
		position: number | number[]
	}
}

export const pageUrlDic: PageUrlDic = {
	[FORMS_ID.CLOTH_DIAPER_FROM]: {
		url: 'https://www.laval.ca/Pages/Fr/Citoyens/couches-lavables.aspx',
		position: 0
	},
	[FORMS_ID.RAIN_WATER_BARREL_FROM]: {
		url: 'https://www.laval.ca/Pages/Fr/Citoyens/baril-de-recuperation-deau-de-pluie.aspx',
		position: 0
	},
	[FORMS_ID.REPLACEMENT_OIL_HEATING_SYSTEM]: {
		url: 'https://www.laval.ca/Pages/Fr/Citoyens/chauffage-au-mazout.aspx',
		position: 0
	},
	[FORMS_ID.HERITAGE_BUILDINGS_REVITALIZATION_PROGRAM]: {
		url: 'https://www.laval.ca/Pages/Fr/Citoyens/batiments-patrimoniaux.aspx',
		position: 0
	},
	[FORMS_ID.CRACKED_HOUSES_FORM]: {
		url: 'https://www.laval.ca/Pages/Fr/Citoyens/maisons-lezardees.aspx',
		position: [0, 1]
	},
	[FORMS_ID.TREE_PLANTING_FORM]: {
		url: 'https://www.laval.ca/Pages/Fr/Citoyens/subvention-plantation-arbres.aspx',
		position: 0
	},
	[FORMS_ID.TEMPORARY_PESTICIDE_APPLICATION_PERMIT_FOR_INDIVIDUALS_FORM]: {
		url: 'https://www.laval.ca/Pages/Fr/Citoyens/utilisation-pesticides.aspx',
		position: [0, 1]
	},
	[FORMS_ID.CLAIM_NOTICE_FORM]: {
		url: 'https://www.laval.ca/Pages/Fr/A-propos/reclamations.aspx',
		position: [0, 1]
	},
	[FORMS_ID.PUBLIC_AUCTIONS]: {
		url: 'https://www.laval.ca/Pages/Fr/A-propos/reclamations.aspx',
		position: 0
	},
	[FORMS_ID.RECRUITMENT_AND_CITIZEN_INVOLVEMENT_FORM]: {
		url: 'https://www.laval.ca/Pages/Fr/A-propos/implication-citoyenne-recrutement.aspx',
		position: 0
	},
	[FORMS_ID.BUILDING_TRANSACTION_REQUEST_FORM]: {
		url: 'https://www.laval.ca/Pages/Fr/Citoyens/achat-vente-terrain.aspx',
		position: [0, 1]
	},
	[FORMS_ID.ANNUAL_REGISTRATION_CERTIFICATE_FOR_CONTRACTORS]: {
		url: 'https://www.laval.ca/Pages/Fr/Citoyens/utilisation-pesticides.aspx',
		position: [0, 1]
	},
	[FORMS_ID.HISTORICAL_ARCHIVE_FORM]: {
		url: 'https://www.laval.ca/histoire-et-patrimoine/Pages/Fr/archives-laval.aspx',
		position: 0
	},
	[FORMS_ID.SELF_RELIEF_WATER_COUNTER_FORM]: {
		url: 'https://www.laval.ca/histoire-et-patrimoine/Pages/Fr/archives-laval.aspx',
		position: 0
	},
	[FORMS_ID.HYDRAULIC_AND_PRESSURE_TESTS_FORM]: {
		url: 'https://www.laval.ca/Pages/Fr/Citoyens/poteaux-incendie.aspx',
		position: 1
	},
	[FORMS_ID.FIRE_HYDRANTS_TEMP_AQUEDUCT_NETWORK]: {
		url: 'https://www.laval.ca/Pages/Fr/Citoyens/poteaux-incendie.aspx',
		position: 2
	},
	[FORMS_ID.FIRE_HYDRANTS_TANK_FILLING]: {
		url: 'https://www.laval.ca/Pages/Fr/Citoyens/poteaux-incendie.aspx',
		position: 0
	},
	[FORMS_ID.ANNUAL_PERMIT_AND_TAG_FOR_ANIMALS]: {
		url: 'https://www.laval.ca/Pages/Fr/Citoyens/permis-et-medaille-annuels.aspx',
		position: 0
	},
	[FORMS_ID.PARKING_PERMIT]: {
		url: 'https://www.laval.ca/Pages/Fr/Citoyens/vignette-stationnement.aspx',
		position: [0, 1, 2, 3, 4, 5, 6]
	},
	[FORMS_ID.PERMIT_OCCUPATION_OF_PUBLIC_HIGHWAY]: {
		url: 'https://www.laval.ca/Pages/Fr/Citoyens/obstruction-voie-publique.aspx',
		position: [0, 1, 2, 3, 4, 5]
	},
	[FORMS_ID.SUSTAINABLE_HYGIENE_PRODUCTS]: {
		url: 'https://www.laval.ca/Pages/Fr/Citoyens/produits-hygiene-durables.aspx',
		position: 0
	},
	[FORMS_ID.CAR_SHARING_GRANT]: {
		url: 'https://www.laval.ca/Pages/Fr/Citoyens/autopartage.aspx',
		position: 0
	},
	[FORMS_ID.BIKE_SHARING_GRANT]: {
		url: 'https://www.laval.ca/Pages/Fr/Citoyens/velo-partage.aspx',
		position: 0
	},
	[FORMS_ID.FIREPLACE_GRANT]: {
		url: 'https://www.laval.ca/Pages/Fr/Citoyens/subvention-foyers-interieurs.aspx',
		position: 0
	},
	[FORMS_ID.SWIMMING_POOL_SPA_PERMIT]: {
		url: 'https://www.laval.ca/Pages/Fr/Citoyens/piscine-spa.aspx',
		position: [0, 1, 2, 3]
	},
	[FORMS_ID.BUILDING_CONSTRUCTION_OR_ADDITION_PERMIT]: {
		url: 'https://www.laval.ca/Pages/Fr/Citoyens/permis-construction.aspx',
		position: 0
	}
}



export const getStepNumber = (subSteps?: thirdStepSubSteps) => {
	const stepNumber: number | undefined = subSteps?.steps?.indexOf(
		subSteps.position
	)
	return stepNumber ? stepNumber + 1 : 1
}

export const getLocaleDate = (value: string) => {
	if (value) {
		return moment(value).utc().format('DD/MM/YYYY')
	}
	return null
}
export function recursiveParse(data: any): any {
	if (typeof data === 'string') {
		try {
			const parsedData = JSON.parse(data)
			if (typeof parsedData === 'string') {
				return recursiveParse(parsedData)
			} else {
				return parsedData
			}
		} catch (error) {
			return data
		}
	} else {
		return data
	}
}

export const demoInvoice: IInvoice = {
	id: '1',
	name: 'Médailles pour animaux',
	totalAmount: 10,
	totalTax: 0,
	grandTotal: 10,
	status: INVOICE_STATUS.waitingForPayment,
	paymentDate: new Date().toISOString(),
	transactionReferenceNumber: undefined,
	detailItems: [
		{
			id: '2',
			description: "Permis annuel d'un chien (2 ans)",
			amount: 40
		},
		{
			id: '3',
			description: 'Gratuité pour les personnes de 65 ans et plus',
			amount: -30
		}
	]
}
